<template>
    <v-text-field v-model="local" v-bind="$attrs" @focusout="onFocusOutHandler"/>
</template>

<script>
import Cleave from 'cleave.js';

export default {
  props: {
    value: {
      type: null,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralPositiveOnly: true,
        numeralPositivePattern: '0,00 $',
      }),
    },
  },
  data() {
    return {
      local: this.formatValue(this.value),
      cleave: null,
    };
  },
  mounted() {
    this.initializeCleave();
  },
  beforeDestroy() {
    this.destroyCleave();
  },
  watch: {
    value(newVal) {
      this.local = this.formatValue(newVal);
      if (this.cleave) {
        this.cleave.setRawValue(newVal);
      }
    },
  },
  methods: {
    initializeCleave() {
      this.cleave = new Cleave(this.$el.querySelector('input'), {
        ...this.options,
        onValueChanged: this.onValueChanged.bind(this),
      });

      this.cleave.setRawValue(this.value);
    },
    destroyCleave() {
      if (this.cleave) {
        this.cleave.destroy();
        this.cleave = null;
      }
    },
    onValueChanged({ target }) {
      this.$nextTick(() => {
        this.local = this.formatValue(target.rawValue);
        this.$emit('input', target?.rawValue);
        this.$emit('input-formatted-value', target?.value);
      });
    },
    formatValue(value) {
      const cleaveInstance = new Cleave(document.createElement('input'), this.options);
      cleaveInstance.setRawValue(value);
      const formattedValue = cleaveInstance.getFormattedValue();
      cleaveInstance.destroy();

      return formattedValue;
    },
    onFocusOutHandler(e) {
      if (this.$listeners.focusout) {
        this.$listeners.focusout(e);
      }
    },
  },
};
</script>
