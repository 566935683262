<template>
  <v-container class="crud-content" fill-height>
    <ListLayout :title="title" :info="info">
      <template v-slot:filter>
        <FilterContainer>
          <template v-slot:content>
            <invoice-form-filter @onSubmit="onSubmitFilterHandler" @onReset="onResetFilterHandler" />
          </template>
        </FilterContainer>
      </template>
      <template v-slot:content>
        <v-data-table
          :headers="header"
          :loading="loading"
          :items="content"
          :sortable="sortable"
          class="elevation-1 custom-table"
          :options.sync="paginationOptions"
          :server-items-length="totalElements"
          multi-sort
          :footer-props="{
            'items-length': 100,
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [10, 20, 50],
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:[`item.provisionItemsIndicator`]="{ item }">
            <v-icon color="red" v-if="item.provisionItemsIndicator">mdi-alert-outline</v-icon>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="camelcase" :class="getColorStatus(item.status)">{{ item.status }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-center">
              <v-icon color="primary" @click="openDetail(item.id)">mdi-magnify-plus-outline</v-icon>
              <v-menu offset-y transition="slide-x-transition">
                <template v-slot:activator="{ attrs, on }">
                  <img src="@/assets/images/icons/MDI - file-download-outline.svg" color="primary" class="ml-1" v-bind="attrs" v-on="on" />
                </template>
                <v-list>
                  <v-list-item v-for="(type, index) in types" :key="index" @click="onDropwdownClickHandler(type, item.id)">
                    <v-list-item-title>{{ type.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-icon
                color="primary"
                v-if="admin && (item.status === statusEnum.FECHADA || item.status === statusEnum.PAGO)"
                @click="openAttachment(item.id)"
                >mdi-paperclip</v-icon
              >
              <!-- <v-icon color="primary" @click="openPayment(item.id)">mdi-file-download-outline</v-icon> -->
              <div class="icon-pagamento" v-if="admin && item.status === statusEnum.FECHADA">
                <v-img @click="openPayment(item.id, item.sellerId)" src="@/assets/images/pagamento.svg" />
              </div>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
        <ExpectedPaymentModal :showModal="showModal" :close="closePayment" :save="saveExpectedPaymentDate" :date="dateExpectedPaymentDate" />
        <AttachmentModal :showModal="showAttachmentModal" :idInvoice="idInvoice" :close="closeAttachment" :save="saveExpectedPaymentDate" />
      </template>
    </ListLayout>
  </v-container>
</template>

<script>
import isAdmin from '@/utils/user-utils';
import ListLayout from '@/components/template/list/ListLayout';
import notification from '@/service/notification';
import dataCrudMixin from '../../components/template/crud/mixins/dataCrudMixin';
import getColorStatus, { statusEnum, statusInitValue } from './util/status';
import getFilters from './util/filters';
import FilterContainer from '@/components/filter-layout/FilterContainer';
import { routerPush } from '@/service/kplaceRouter';
import ExpectedPaymentModal from './forms/ExpectedPaymentModal';
import AttachmentModal from './forms/AttachmentModal';
import getInvoice, { saveExpectedPaymentDateInvoice, getReport, getListInvoice } from '@/service/invoice/invoice-service';
import { getSeller } from '@/service/seller/seller-service';
import { formatMountDate } from '@/utils/format-utils';
import nextMonth from '@/utils/date-utils';
import InvoiceFormFilter from './filter/InvoiceFormFilter';

export default {
  components: {
    ListLayout,
    FilterContainer,
    ExpectedPaymentModal,
    AttachmentModal,
    InvoiceFormFilter,
  },
  mixins: [dataCrudMixin],
  data() {
    return {
      sortable: {
        id: 'DESC',
      },
      types: [{ title: 'CSV' }, { title: 'PDF' }],
      formDataFilter: {},
      paginationOptions: {},
      totalElements: 0,
      showModal: false,
      showAttachmentModal: false,
      idInvoice: null,
      search: '',
      title: 'Faturas de pagamentos',
      loading: false,
      info: 'Acesse suas faturas de recebimento dos pagamentos das nossas platafromas de venda.',
      filters: getFilters(),
      header: [
        { text: 'ID da Fatura', value: 'id' },
        { text: 'Período do faturamento', value: 'period' },
        { text: 'Data fechamento', value: 'closingDay' },
        { text: 'Valor total', value: 'total' },
        { text: '', value: 'provisionItemsIndicator', sortable: false },
        { text: 'Data de pagto.', value: 'paymentDate' },
        { text: 'Situação', value: 'status' },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ],
      admin: isAdmin,
      statusEnum,
      dateExpectedPaymentDate: null,
    };
  },
  watch: {
    paginationOptions: {
      handler() {
        this.init();
      },
    },
  },
  async mounted() {
    this.formDataFilter.status = statusInitValue;
    await this.init();
  },
  methods: {
    getColorStatus,
    init() {
      this.getInvoiceListHandler(this.formDataFilter);
    },
    onSubmitFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getInvoiceListHandler(formDataFilter);
    },
    onResetFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getInvoiceListHandler(formDataFilter);
      this.paginationOptions.page = 1;
    },
    async getInvoiceListHandler(formDataFilter) {
      try {
        this.loading = true;

        const { items, totalElements } = await getListInvoice(formDataFilter, this.paginationOptions);
        this.content = items;
        this.totalElements = totalElements;

        this.loading = false;
      } catch (error) {
        notification('Erro ao realizar busca das faturas', 'error');
        console.error('Error on getInvoiceListHandler', error);
        this.loading = false;
      }
    },
    openDetail(id) {
      routerPush(`/invoice/detail/${id}`);
    },
    openPayment(id, sellerId) {
      this.idInvoice = id;
      this.getDateExpectedPaymentDate(sellerId);
      this.showModal = true;
    },
    openAttachment(id) {
      this.idInvoice = id;
      this.showAttachmentModal = true;
    },
    closePayment() {
      this.showModal = false;
      this.dateExpectedPaymentDate = null;
      this.idInvoice = null;
    },
    closeAttachment() {
      this.idInvoice = null;
      this.showAttachmentModal = false;
    },
    async saveExpectedPaymentDate(form) {
      try {
        const response = await saveExpectedPaymentDateInvoice(this.idInvoice, form);
        if (response.status === 204) {
          notification('O pagamento da fatura foi informado ao seller com sucesso.', 'success');
          this.closePayment();
          this.init();
        } else {
          notification(`Não foi possível informar o pagamento: ${response.response.data.message}`, 'error');
        }
      } catch (error) {
        notification('Não foi possível informar o pagamento.', 'error');
      }
    },
    async getDateExpectedPaymentDate(sellerId) {
      const seller = await getSeller(sellerId);
      const date = new Date();
      const current = date.getDate() <= seller.sellerFinancialAgreement.recurringDays ? date : nextMonth(date);
      // eslint-disable-next-line vue/max-len
      this.dateExpectedPaymentDate = formatMountDate(current.getFullYear(), current.getMonth(), seller.sellerFinancialAgreement.recurringDays);
    },
    async getDataInvoice(id) {
      this.form = await getInvoice(id);
      if (this.form.ableToClosePeriod) this.form.status = statusEnum.AGUARDANDO_FECHAMENTO;
    },
    async getReport(name, type) {
      const data = {
        name,
        content: JSON.stringify(this.form),
      };
      const response = await getReport(data, type);
      return response;
    },
    async onDropwdownClickHandler(type, id) {
      await this.getDataInvoice(id);
      const title = type.title.toLowerCase();
      const response = await this.getReport('invoice-detailing', title);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoiceDetailing.${title}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
  },
};
</script>

<style lang="scss">
.crud-content {
  background-color: var(--background-3);
  height: calc(100% - 160px);
  width: 100%;
  margin-top: 126px;
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
}

.status {
  &--aguardando {
    color: var(--orange-0);
  }
  &--paga {
    color: var(--green-1);
  }
}

.icon-pagamento {
  height: 24px !important;
  width: 24px !important;
  cursor: pointer;
}
</style>
