<template>
<CustomModal :showModal="showModal" :persistent="true">
    <template v-slot:header>
      <div class="mb-6">Anexar documentos e comprovantes da fatura</div>
    </template>
    <template v-slot:content>
      <div class="d-flex flex-column">
        <div class="header-sub mb-6">Anexe e gerencie os arquivos desta fatura.</div>
        <div class="d-flex mb-3 mt-5 text flex-grow-1 align-items-center">
          <div class="name">Anexo*</div>
          <div class="object">
            <v-file-input placeholder="Selecione o arquivo" variant="outlined" prepend-icon="" truncate-length="50" v-model="file" filled light />
          </div>
        </div>
        <div class="d-flex my-3 text align-content-center">
          <div class="name">
            Descrição*
          </div>
          <div class="object">
            <v-textarea v-model="description" variant="outlined" placeholder="Digite uma descrição para o arquivo" filled dense light></v-textarea>
          </div>
        </div>
        <div class="align-self-end">
          <v-btn outlined color="primary" class="mr-5" @click="cleanModal()" :disabled="!file && !description">LIMPAR</v-btn>
          <v-btn :loading="loading" color="primary" @click="saveAttachment()" :disabled="!file || !description">ANEXAR</v-btn>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex flex-column">
        <div class="mb-4">Anexos</div>
        <div v-if="content.length > 0">
          <v-data-table
            :headers="header"
            :items="content"
            class="elevation-1 custom-table"
            :footer-props="{
              'items-length': 100,
              'items-per-page-text': 'Itens por página',
              'items-per-page-options': [10, 20, 50],
              pageText: '{0}-{1} de {2}',
            }"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex justify-center">
                <v-icon color="primary" @click="downloadFile(item.storageKey)" class="mr-3">mdi-download</v-icon>
                <v-icon color="primary" @click="deleteItem(item.id)" class="mr-3">mdi-delete</v-icon>
              </div>
            </template>
            <template v-slot:no-data>
              <div class="pt-10 pb-10">
              Nenhum resultado encontrado
              </div>
            </template>
          </v-data-table>
        </div>
        <div v-else class="header-sub">Esta fatura ainda não possui nenhum arquivo anexado.</div>
        <div class="align-self-end mt-10">
          <v-btn outlined color="primary" @click="closeModal()" :disabled="loading == null ? false : loading">FECHAR</v-btn>
        </div>
      </div>
    </template>
  </CustomModal>
</template>
<script>
import CustomModal from '@/components/modal/CustomModal';
import { listAttachment, deleteAttachment, saveUploadAttachment } from '@/service/invoice/invoice-service';

import notification from '@/service/notification';
import uploadAttachment, { downloadAttachment } from '@/service/upload/upload-service';
import { formatDateWithoutHour } from '@/utils/format-utils';

export default {
  data() {
    return {
      loading: false,
      file: null,
      description: null,
      attachement: null,
      header: [
        { text: 'Arquivo', value: 'name' },
        { text: 'Descrição', value: 'description' },
        { text: 'Data', value: 'createdAt' },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ],
      content: [],
    };
  },
  components: {
    CustomModal,
  },
  props: {
    showModal: { type: Boolean, default: false },
    close: { type: Function },
    save: { type: Function },
    idInvoice: { type: String },
  },
  watch: {
    idInvoice: {
      handler() {
        if (this.showModal) {
          this.getList();
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.$props.close();
      this.cleanModal();
    },
    savePayment(expectedPaymentDate) {
      this.$props.save(expectedPaymentDate);
      this.closeModal();
    },
    cleanModal() {
      this.file = null;
      this.description = null;
      this.attachement = null;
      this.loading = false;
    },
    async saveAttachment() {
      this.loading = true;
      await this.uploadAttachment();
      if (this.attachement) {
        await this.saveUploadAttachment();
      }
      await this.getList();
    },
    async getList() {
      try {
        const response = await listAttachment(this.idInvoice);
        this.content = response.data.register;
        this.content = this.content.map((item) => ({ ...item, createdAt: formatDateWithoutHour(item.createdAt) }));
      } catch (error) {
        this.loading = false;
        notification('Erro ao listar o arquivo', 'error');
        console.error('getList', error);
      }
    },
    async downloadFile(storageKey) {
      try {
        await downloadAttachment(storageKey);
      } catch (error) {
        notification('Erro ao fazer o download do arquivo', 'error');
        console.error('downloadFile', error);
      }
    },
    async deleteItem(id) {
      try {
        const response = await deleteAttachment(this.idInvoice, id);
        if (response.status === 200) {
          notification('Arquivo deletado', 'success');
          this.getList();
        } else notification(`Erro ao deletado o arquivo: ${response.response.data.message}`, 'error');
      } catch (error) {
        notification('Erro ao deletado o arquivo', 'error');
        console.error('deleteItem', error);
      }
    },
    async uploadAttachment() {
      try {
        const formData = new FormData();
        if (this.file) formData.append('file', this.file, this.file.name);
        const response = await uploadAttachment(formData);
        if (response.status === 200) {
          this.attachement = {
            id: response.data.id,
            storageKey: response.data.id,
            name: response.data.fileName,
            url: response.data.folder + response.data.id,
            description: this.description,
          };
        } else {
          this.loading = false;
          notification(`Erro ao anexar o arquivo: ${response.response.data.message}`, 'error');
        }
      } catch (error) {
        this.loading = false;
        notification('Erro ao anexar o arquivo', 'error');
        console.error('uploadAttachment', error);
      }
    },
    async saveUploadAttachment() {
      try {
        const response = await saveUploadAttachment(this.idInvoice, this.attachement);
        if (response.status === 200) {
          notification('Arquivo anexado com sucesso.', 'success');
          this.cleanModal();
        } else {
          this.loading = false;
          notification(`Erro ao salvar o arquivo: ${response.response.data.message}`, 'error');
        }
      } catch (error) {
        this.loading = false;
        notification('Erro ao salvar arquivo', 'error');
        console.error('saveUploadAttachment', error);
      }
    },
  },
};
</script>

<style scoped>
.header-sub {
  font-size: 14px;
}
.text {
  font-size: 16px;
  color: #212529;
}
.name {
  width: 15%;
}
.object {
  width: 85%;
}
</style>
