<template>
  <div>
    <div class="d-flex flex-column">
      <slot name="content"></slot>

      <div class="d-flex filter-actions pt-4">
        <slot name="actions"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.filter-actions {
  display: flex;
  justify-content: end;
}
</style>
