import moment from 'moment';

export function mapperToInvoiceResponse(formData) {
  const invoice = formData;
  invoice.items = formData.items.map((item) => {
    const formattedDate = moment(item.dateAndHour, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    return {
      ...item,
      description: item.installments > 1 ? `${item.description} ${item.installment}/${item.installments}` : `${item.description}`,
      dateAndHourFormat: formattedDate,
    };
  });
  return invoice;
}

export default mapperToInvoiceResponse;
