<template>
  <v-form ref="filterForm" lazy-validation @submit="onSubmitHandler">
    <content-base-layout>
      <template v-slot:content>
        <div class="form-content">
          <!-- <div class="col-3">
            <label class="filter-label">ID da fatura </label>
            <v-text-field v-model="form.id" dense filled />
          </div>
          <div class="col-3">
            <label class="filter-label">Data inicial </label>
            <mask-input
              v-model="form.initialDate"
              :options="DateMaskOptions"
              dense
              filled
              placeholder="dd/mm/aaaa"
              @input-formatted-value="onMaskInputDateChange($event, 'initialDate')"
              :rules="[Rules.dateFullyFilled]"
            />
          </div>
          <div class="col-3">
            <label class="filter-label">Data final </label>
            <mask-input
              v-model="form.finalDate"
              :options="DateMaskOptions"
              dense
              filled
              placeholder="dd/mm/aaaa"
              @input-formatted-value="onMaskInputDateChange($event, 'finalDate')"
              :rules="[Rules.dateFullyFilled]"
            />
          </div> -->
          <div class="col-3">
            <label class="filter-label">Data de pagamento </label>
            <mask-input
              v-model="form.paymentDate"
              @input-formatted-value="onMaskInputDateChange($event, 'paymentDate')"
              :options="DateMaskOptions"
              dense
              filled
              placeholder="Informe"
              :rules="[Rules.dateFullyFilled]"
            />
          </div>
          <div class="col-3">
            <label class="filter-label">Número do pedido </label>
            <v-text-field v-model="form.numberOrder" placeholder="Digite" dense filled />
          </div>
          <div class="col-6">
            <label class="filter-label">Situação </label>
            <v-autocomplete v-model="form.status" :items="StatusOptions" item-text="label" item-value="value" filled dense multiple>
              <template v-slot:selection="data">
                <v-chip small>
                  {{ data.item.chip || data.item.label }}
                </v-chip>
              </template>
            </v-autocomplete>
          </div>
        </div>
      </template>

      <template v-slot:actions>
        <filter-base-actions @onReset="onClearHandler" />
      </template>
    </content-base-layout>
  </v-form>
</template>

<script>
import moment from 'moment';
import ContentBaseLayout from '@/components/filter-layout/ContentBaseLayout';
import FilterBaseActions from '@/components/filter-layout/FilterBaseActions';
import MaskInput from '@/components/template/form/input/MaskInput';
import { statusOptions, statusInitValue } from '../util/status';

const FilterFields = {
  id: '',
  initialDate: '',
  finalDate: '',
  paymentDate: '',
  status: statusInitValue,
  numberOrder: null,
};

const DateMaskOptions = {
  date: true,
  datePattern: ['d', 'm', 'Y'],
  delimiter: '/',
  dateMin: '01/01/1900',
  dateMax: '12/31/2099',
  placeholder: 'DD/MM/YYYY',
};

export default {
  components: {
    MaskInput,
    ContentBaseLayout,
    FilterBaseActions,
  },
  computed: {
    StatusOptions() {
      return statusOptions;
    },
    DateMaskOptions() {
      return DateMaskOptions;
    },
    Rules() {
      return {
        dateFullyFilled: (value) => {
          if (!value) return true;
          const numberValue = value.replace(/\D/g, '');

          if (numberValue.length < 8) {
            return 'Preencha a data corretamente. Ex: dd/mm/yyyy';
          }

          return true;
        },
      };
    },
  },
  data() {
    return {
      form: { ...FilterFields },
      formFormattedValues: {
        initialDate: '',
        finalDate: '',
        paymentDate: '',
      },
    };
  },
  methods: {
    onSubmitHandler(e) {
      e.preventDefault();
      const { filterForm } = this.$refs;
      const validated = filterForm?.validate();

      if (!validated) return;
      const formData = { ...this.form, ...this.formFormattedValues };

      this.$emit('onSubmit', formData);
    },
    onClearHandler() {
      this.form = { ...this.form, ...FilterFields };
      this.formFormattedValues = {
        initialDate: '',
        finalDate: '',
        paymentDate: '',
      };
      this.FilterFields = {
        id: '',
        initialDate: '',
        finalDate: '',
        paymentDate: '',
        status: statusInitValue,
        numberOrder: null,
      };
      this.$emit('onReset', this.form);
    },
    onMaskInputDateChange(value, fieldName) {
      try {
        if (!value) {
          this.formFormattedValues[fieldName] = '';
          return;
        }
        this.formFormattedValues[fieldName] = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
      } catch (error) {
        console.error('Error on DataFilter component at onMaskInputDateChange method. ', error);
      }
    },
  },
};
</script>
