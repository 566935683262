export const statusEnum = {
  EM_ABERTO: 'EM ABERTO',
  AGUARDANDO_FECHAMENTO: 'AGUARDANDO FECHAMENTO',
  PAGA: 'PAGA',
  PAGO: 'PAGO',
  FECHADA: 'FECHADA',
};

export default function getColorStatus(status) {
  switch (status) {
    case statusEnum.PAGO:
      return 'status--paga';
    case statusEnum.AGUARDANDO_FECHAMENTO:
      return 'status--aguardando';
    default:
      return '';
  }
}

export const statusOptions = [
  { label: 'Em aberto', value: 'OPEN' },
  { label: 'Aguardando fechamento', value: 'WAITING_CLOSE', chip: 'Aguard. fech.' },
  { label: 'Paga', value: 'PAID' },
  { label: 'Fechada', value: 'CLOSED' },
  { label: 'Futura', value: 'FUTURE' },
];

export const statusInitValue = ['OPEN', 'WAITING_CLOSE', 'PAID', 'CLOSED'];
