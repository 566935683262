<template
  ><AlertModal :showModal="showModal">
    <template v-slot:header>
      <div class="d-flex flex-column">
        Atenção!
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex flex-column">
        <div class="mb-5">
          Após informar ao seller sobre o pagamento da fatura, esta processo não poderá mais ser desfeito.
        </div>
        <div>
          Também não será mais permitida a alteração da data de previsão de pagamento. Se necessário, altere agora a data no campo abaixo.
        </div>
        <div class="d-flex flex-grow-1 justify-space-around align-center my-5">
          <div>Previsão de pagamento</div>
          <div class="d-flex align-center">
            <v-text-field class="custom-input" type="date" v-model="form.expectedPaymenDate" filled dense light />
          </div>
        </div>
        <div class="mt-3">Deseja mesmo informar o pagamento da fatura?</div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex flex-grow-1 justify-space-around align-center">
        <v-btn outlined color="primary" @click="closeModal()">CANCELAR</v-btn>
        <v-btn color="primary" :disabled="!form.expectedPaymenDate" @click="savePayment(form)">SIM, INFORMAR PAGAMENTO</v-btn>
      </div>
    </template>
  </AlertModal>
</template>
<script>
import AlertModal from '@/components/modal/AlertModal';
import { formatDateBrazil } from '@/utils/format-utils';

export default {
  data() {
    return { form: { expectedPaymenDate: null } };
  },
  components: {
    AlertModal,
  },
  watch: {
    date: {
      handler(date) {
        this.form.expectedPaymenDate = date;
      },
    },
  },
  props: {
    showModal: { type: Boolean, default: false },
    close: { type: Function },
    save: { type: Function },
    date: { type: String },
  },
  methods: {
    closeModal() {
      this.$props.close();
    },
    savePayment(form) {
      form.expectedPaymenDate = formatDateBrazil(form.expectedPaymenDate);
      this.$props.save(form);
      this.closeModal();
    },
  },
};
</script>
