import { post } from '@/service/api';

export default async function uploadAttachment(formData) {
    const response = await post('upload-service/file/upload', formData);
return response;
}

export async function downloadAttachment(id) {
  window.open(`${process.env.VUE_APP_URL_API}/upload-service/file/download/${id}`, '_parent');
}
