import { statusOptions } from './status';

export default function getFilters() {
  return [
    {
      name: 'id',
      label: 'ID da fatura',
      placeHolder: 'Pesquise pela identificação da fatura...',
      xl: '3',
      lg: '3',
      type: 'text',
      filled: true,
    },
    {
      name: 'initialDate',
      label: 'Data inicial',
      xl: '3',
      lg: '3',
      type: 'mask-input-date',
      filled: true,
    },
    {
      name: 'finalDate',
      label: 'Data final',
      xl: '3',
      lg: '3',
      type: 'mask-input-date',
      filled: true,
    },
    {
      name: 'total',
      label: 'Valor Total',
      width: '10%',
      xl: '3',
      lg: '3',
      placeHolder: 'Pesquise pelo valor total da fatura...',
      type: 'positive-number',
      filled: true,
    },
    {
      name: 'paymentDate',
      label: 'Data de pagamento',
      xl: '3',
      lg: '3',
      type: 'mask-input-date',
      filled: true,
    },
    {
      name: 'status',
      label: 'Situação',
      xl: '3',
      lg: '3',
      placeHolder: 'Pesquise pela situação da fatura...',
      type: 'select',
      filled: true,
      options: statusOptions,
    },
    {
      name: 'numberOrder',
      label: 'Número do pedido',
      xl: '3',
      lg: '3',
      placeHolder: 'Pesquise pela número do pedido...',
      type: 'search',
      filled: true,
    },
  ];
}
