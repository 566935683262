import moment from 'moment/moment';
import { get, post, put, remove } from '@/service/api';
import remapSortDesc, { buildSortUrl, buildUrl } from '@/utils/service-utils';
import { mapperToInvoiceResponse } from './invoice-mapper';

const seller = localStorage.getItem('sellerId');

export default async function getInvoice(id) {
  const { data } = await get(`ui-integrator/invoice/${id}?sellerId=${seller}`);
  const mapper = await mapperToInvoiceResponse(data);
  return mapper;
}

export async function getReport(data, type) {
  try {
    const response = await post(`report/${type}`, data, { responseType: 'blob' });
    return response.data;
  } catch (e) {
    console.error('error: ', e);
    return null;
  }
}

export async function listInvoice(page, itemsPerPage, query, sortBy, orderBy) {
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = remapSortDesc(orderBy);
  let sortOptions = buildSortUrl(sortBy, mapedOrder);
  if (query.length > 0) query.replaceAll('initialDate =', 'submittedAt >=').replaceAll('finalDate =', 'submittedAt <=');
  if (sortOptions.length > 0) {
    sortOptions = sortOptions.replaceAll('closingDay', 'finalDate');
    sortOptions = sortOptions.replaceAll('period', 'initialDate');
  }
  const data = await get(`ui-integrator/invoice/fetch?${params}&filter=${query}${sortOptions}`);
  return data;
}
export async function getListInvoice(formData = {}, paginationOptions) {
  const { page, itemsPerPage, sortDesc, sortBy } = paginationOptions;
  const params = await buildUrl(page, itemsPerPage);
  const mapedOrder = remapSortDesc(sortDesc);
  let sortOptions = buildSortUrl(sortBy, mapedOrder);

  // eslint-disable-next-line vue/max-len
  const { initialDate, finalDate, id, paymentDate, status, numberOrder } = formData;

  const query = [
    id ? `( id = ${id} )` : '',
    initialDate ? `( initialDate >= ${initialDate} )` : '',
    finalDate ? `( finalDate <= ${finalDate} )` : '',
    status && status.length > 0 ? `( status in ${status} )` : '',
    paymentDate ? `( paymentDate = ${paymentDate} )` : '',
    numberOrder ? `( numberOrder = ${numberOrder} )` : '',
  ]
    .filter((condition) => condition !== '')
    .join(' and ');

  if (sortOptions.length > 0) {
    sortOptions = sortOptions.replaceAll('closingDay', 'finalDate');
    sortOptions = sortOptions.replaceAll('period', 'initialDate');
  }

  const { data } = await get(`ui-integrator/invoice/fetch?${params}&filter=${query}${sortOptions}`);
  const { register, totalRegister } = data;

  const remapedRegisters =
    register?.map((item) => ({
      ...item,
    })) || [];

  return { items: remapedRegisters, totalElements: totalRegister };
}

export async function closeInvoice(id) {
  const data = await post(`ui-integrator/invoice/${id}/close`);
  return data;
}

export async function saveExpectedPaymentDateInvoice(id, form) {
  const data = await put(`ui-integrator/invoice/${id}/expectedPaymentDate`, form);
  return data;
}

export async function listAttachment(idInvoice) {
  const data = await get(`ui-integrator/invoice/${idInvoice}/documents`);
  return data;
}

export async function deleteAttachment(idInvoice, idAttachment) {
  const data = await remove(`ui-integrator/invoice/${idInvoice}/documents/${idAttachment}`);
  return data;
}

export async function saveUploadAttachment(idInvoice, attachement) {
  const data = await post(`ui-integrator/invoice/${idInvoice}/documents`, attachement);
  return data;
}

export async function saveSellerCommissionedItem(form) {
  const data = await post('sellers/invoice/seller-commissioned-item', form);
  return data;
}
