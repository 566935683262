<template>
  <v-dialog v-model="showModal" :max-width="maxWidth" :persistent="persistent">
    <template>
      <v-card class="template">
        <v-card-text>
          <div class="custom-header">
            <slot name="header"></slot>
          </div>
          <div class="custom-content">
            <slot name="content"></slot>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="custom-footer">
            <slot name="footer"></slot>
          </div>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxWidth: {
      type: String || Number || undefined,
      default: '850',
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-header {
  font-size: 16px;
  color: var(--grey-0);
  justify-content: left;
  text-align: left;
  margin: 10px 27px;
  padding-top: 30px;
}

.custom-content {
  font-size: 16px;
  color: var(--grey-4);
  margin: 10px 27px;
  text-align: left;
  height: 500px;
  overflow: auto;
}

.custom-footer {
  font-size: 16px;
  color: var(--grey-4);
  margin: 10px 27px;
  width: 100%;
}

.template {
  background-color: var(--background-3);
}
</style>
